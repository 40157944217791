import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Pre Open WOD 4`}</em></strong></p>
    <p><em parentName="p">{`RX Division`}</em></p>
    <p>{`16:00 AMRAP of:`}</p>
    <p>{`10-Deadlifts (185/125)`}</p>
    <p>{`12-Ring Dips`}</p>
    <p>{`14-Box Jumps (24/20″, no step ups)`}</p>
    <p><em parentName="p">{`Masters/Scaled Division`}</em></p>
    <p>{`16:00 AMRAP of:`}</p>
    <p>{`10-Deadlifts (135/95)`}</p>
    <p>{`12-Ring Dips (Men use blue band/Women use medium, green or purple, band)`}</p>
    <p>{`14-Box Jumps (24/20″, step ups are allowed)`}</p>
    <p><em parentName="p">{`*`}{`Full extension on the deadlifts, no bouncing the weight off the
floor`}</em></p>
    <p><em parentName="p">{`*`}{`Chest to rings at the bottom of the dip, full lock out at the top`}</em></p>
    <p><em parentName="p">{`*`}{`Full extension at the top of each box jump`}</em></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. Want to
learn the exact number of calories your body burns each day? Metabolic
Testing will be offered before and after the seminar as well!  We will
need 20 people in order to have this seminar so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend.  \\$35/person
for the seminar.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      